

.centerImage {
    max-height:50%; 
    max-width:50%;
    position: relative;
}

.centerImageOne {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* width: 100%;  */
    /* height: 100px; */
    /* position: absolute; */
    /* top: 41%;
    left: 50%; */
    /* transform: translateX(-50%) translateY(-50%); */
}

::-webkit-scrollbar {
    background-color: #f5f5f5;
    border-radius: 10px;
    width: 7px;
    height: 2px;
}
::-webkit-scrollbar-thumb {
    background-color: #a19f9f;
    border-radius: 10px;
}


.overlayClient{
  position: absolute;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.62); /* Black see-through */
    width: 100%;
    transition: .5s ease;
    font-size:30px;
    padding-top: 80%;
    font-weight: bolder;
    text-align: center;
    opacity: 0.9;
    height: -webkit-fill-available;
    margin: auto;
}
.overlayDeclarator {
    position: absolute;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.62); /* Black see-through */
    width: 100%;
    transition: .5s ease;
    font-size: 23px;
    padding-top: 100%;
    font-weight: bolder;
    text-align: center;
    opacity: 0.9;
    height: -webkit-fill-available;
    margin: auto;
  }

  .overlayDeclaratorCurrent {
    position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.6); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 30px;
  font-weight: bolder;
  padding-top: 45%;
  text-align: center;
  height: 100%;
    opacity: 1;

    /* margin-left: 30px;
    padding-top: 20%;
    height: 190px;
    width: 32%;
    top: 190px;

    padding-top: 5%;
    height: 175px;
    width: 12%;
    top: 40px; */

  }

  /* .containerImage{
      position: relative;
  width: 50%;
  max-width: 300px;
  } */

  .container11 {
    position: relative;
    width: 50%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .image11 {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .overlay11 {
    position: absolute; 
    bottom: 0; 
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1; 
    width: 90%;
    transition: .5s ease;
    opacity:0;
    color: white;
    /* padding: 20px; */
    height: 100%;
    font-weight: bold;
    text-align: center;
  }


  
  
  .container11 .overlay11 {
    opacity: 1;
  }

  .statusCss{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    color: rgb(25, 247, 5); opacity: .8;
    z-index: 1000;
  }

  .statusUnsold{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    color: rgb(247, 5, 5); opacity: 1.8;
    z-index: 1000;
  }


  /* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .statusCss {
    font-size: 50px;
  }
  .statusUnsold {
    font-size:40px;
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .statusCss {
    font-size: 70px;
  }
  .statusUnsold {
    font-size:60px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .statusCss {
    font-size: 70px;
  }
  .statusUnsold {
    font-size: 65px;

  }
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .statusCss {
    font-size: 75px;
  }
  .statusUnsold {
    font-size: 60px;
    
  }
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .statusCss {
    font-size: 100px;
  }
  .statusUnsold {
    font-size: 70px;

  }
}

#floor{
  padding: 18px 10px !important;
  border: 0px  !important;
}

.nextButton:hover{
  background: #1f1d1d;
}

.nextButton {
  background-color: #555;
  border-radius:5;
  opacity: .8;

}