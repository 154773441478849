



.centerImage {
    max-height:50%; 
    max-width:50%;
           
}

.centerImageOne {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* width: 100%;  */
    /* height: 100px; */
    /* position: absolute; */
    /* top: 41%;
    left: 50%; */
    /* transform: translateX(-50%) translateY(-50%); */
}

::-webkit-scrollbar {
    background-color: #f5f5f5;
    border-radius: 10px;
    width: 7px;
    height: 2px;
}
::-webkit-scrollbar-thumb {
    background-color: #a19f9f;
    border-radius: 10px;
}

.overlayDeclarator {
  position: absolute;
  /* bottom: 0; */
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.62); /* Black see-through */
  /* color: #f1f1f1; */
  width: 100%;
  transition: .5s ease;
  opacity:0;
  /* color: white; */
  font-size: 30px;
  padding-top: 85%;
  font-weight: bolder;
  /* padding: 20px; */
  text-align: center;
  opacity: 1;
  height: -webkit-fill-available;
  margin: auto;
}

.overlay_sold {
    height: 100%;
    position: absolute; 
    bottom: 0; 
    background: rgb(0, 0, 0);
    background: #00000099; /* Black see-through */
    /* color: green;  */
    width: 100%;
    transition: .5s ease;
    opacity:0;
    color: rgb(6, 214, 6);
    font-size: 25px;
    padding-top: 25px;
    text-align: center;
    font-weight: bold;
    opacity: 1;
  }
  .overlay_Unsold {
    height: 100%;
    position: absolute; 
    bottom: 0; 
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.6); /* Black see-through */
    /* color: red;  */
    width: 100%;
    transition: .5s ease;
    opacity:0;
    color: rgb(247, 38, 38);
    font-size: 25px;
    padding-top: 25px;
    text-align: center;
    font-weight: bold;
    opacity: 1;
  }

.overlay_sold_mobile{
    /* position: absolute;  */
    bottom: 0; 
    background: rgb(10, 167, 15);
    background: rgba(10, 167, 15.5); /* Black see-through */
    color: #f1f1f1; 
    width: 100%;
    transition: .5s ease;
    opacity:0;
    color: white;
    /* font-size: 20px;
    padding: 20px; */
    text-align: center;
    opacity: 1;
  }
  .overlay_Unsold_mobile {
    /* position: absolute; 
    font-size: 20px;
    padding: 20px; */
    bottom: 0; 
    background: rgb(206, 17, 17);
    background: rgba(206, 17, 17.5); /* Black see-through */
    color: #f1f1f1; 
    width: 100%;
    transition: .5s ease;
    opacity:0;
    color: white;
    text-align: center;
    opacity: 1;
  }
  .boxStatusOne{
    z-index: 1400;
    position: absolute;
    display: flex;
    /* left: 8px;
    right: 8px; */
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    /* bottom: 8px; */
  }

  .boxStatus {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: none;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: flex;
    padding: 6px 16px;
    font-weight: 500;
    width: 100%;
  }


  .container {
    position: relative;

  }
  
  /* Make the image to responsive */
  .image {
    display: block;
    width: 100%;
    height: auto;
  }
  
  /* The overlay effect - lays on top of the container and over the image */
  .overlay {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.62); /* Black see-through */
    /* color: #f1f1f1; */
    width: 40%;
    transition: .5s ease;
    opacity:0;
    /* color: white; */
    font-size: 45px;
    padding-top: 180px;
    font-weight: bolder;
    /* padding: 20px; */
    text-align: center;
    opacity: 1;
    height: -webkit-fill-available;
    margin-left: 30%;
  }
  
  /* .imageHover:visited{
    border: 4px solid green;
  } */
  /* .imageHover:hover {
  border: 2px solid rgb(68, 68, 68);
  /* padding: 2px; 
  }
   */

   .nextButton:hover{
    background: #1f1d1d;
  }
  
  .nextButton {
    background-color: #555;
    border-radius:5;
    opacity: .8;
  
  }

  