@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Bell MT";
  /* font-family: "Poppins", sans-serif; */
}
body {
  min-height: 100vh;
  width: 100%;
  background: #ebe9e9;
}

.logincontainer {
  /* position: absolute; */
  top: 100px;
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  max-width: 600px;
  /* width: 100%; */
  margin: auto;
  background: #fff;
  border-radius: 7px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}
.logincontainer .registration {
  display: none;
}
#check:checked ~ .registration {
  display: block;
}
#check:checked ~ .login {
  display: none;
}
#check {
  display: none;
}
.logincontainer .form {
  padding: 2rem;
}
.form header {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.5rem;
  padding: 10px;
}
.form input {
  height: 60px;
  width: 100%;
  padding: 0 15px;
  font-size: 17px;
  margin-bottom: 1.3rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  outline: none;
}
.form input:focus {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}
.textfield:focus {
  border: 2px solid #1d3ae2;
  outline-color: #3b55e7;
}
.form a {
  font-size: 16px;
  color: #6e7271;
  text-decoration: none;
}
.form a:hover {
  text-decoration: underline;
}
.form input.button {
  color: #fff;
  background: #636665;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: 1.7rem;
  cursor: pointer;
  transition: 0.4s;
}
.form input.button:hover {
  background: #1d3ae2;
}
.signup {
  font-size: 17px;
  text-align: center;
}
.signup label {
  color: #1d3ae2;
  cursor: pointer;
}
.signup label:hover {
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  .logincontainer {
    /* width: 100%; */
    background: white;
    box-shadow: 0 0px 0px white;
  }
  .logo {
    margin-bottom: 50px;    
    width: 100%;
  }
  body {
    background: white;
  }
}

@media screen and (min-width: 1020px) {
  .imageLog {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    padding-bottom: 50px;
  }
}

